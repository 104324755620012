exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-associar-se-mdx": () => import("./../../../src/pages/associar-se.mdx" /* webpackChunkName: "component---src-pages-associar-se-mdx" */),
  "component---src-pages-canviar-contrasenya-mdx": () => import("./../../../src/pages/canviar-contrasenya.mdx" /* webpackChunkName: "component---src-pages-canviar-contrasenya-mdx" */),
  "component---src-pages-entrar-mdx": () => import("./../../../src/pages/entrar.mdx" /* webpackChunkName: "component---src-pages-entrar-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-privacitat-mdx": () => import("./../../../src/pages/privacitat.mdx" /* webpackChunkName: "component---src-pages-privacitat-mdx" */),
  "component---src-pages-recuperar-contrasenya-mdx": () => import("./../../../src/pages/recuperar-contrasenya.mdx" /* webpackChunkName: "component---src-pages-recuperar-contrasenya-mdx" */),
  "component---src-pages-socis-canviar-targeta-mdx": () => import("./../../../src/pages/socis/canviar-targeta.mdx" /* webpackChunkName: "component---src-pages-socis-canviar-targeta-mdx" */),
  "component---src-pages-socis-index-mdx": () => import("./../../../src/pages/socis/index.mdx" /* webpackChunkName: "component---src-pages-socis-index-mdx" */),
  "component---src-pages-socis-pagar-mdx": () => import("./../../../src/pages/socis/pagar.mdx" /* webpackChunkName: "component---src-pages-socis-pagar-mdx" */),
  "component---src-pages-socis-rebuts-mdx": () => import("./../../../src/pages/socis/rebuts.mdx" /* webpackChunkName: "component---src-pages-socis-rebuts-mdx" */)
}

