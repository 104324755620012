import { graphql, useStaticQuery, navigate } from 'gatsby';
import React, { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';

import { get, post, put } from '../api';
import { Carregant } from './Carregant';
import { ErrorsAlFormulari, FuncióPerEnviarFormulari } from './Formulari';
import { Quota } from './Quotes';

export const CAMPS_USUARI = [
  'id', 'correu_electrònic', 'contrasenya',
  'nom', 'identificació', 'telèfon',
  'adreça1', 'adreça2', 'població', 'codi_postal', 'pais',
  'permís_per_enviar_correus_electrònics'] as const;
export type CampUsuari = typeof CAMPS_USUARI[number];
export type ParàmetresDEntrada = { correu_electrònic: string, contrasenya: string };
export type ParàmetresDAlta = Partial<Record<CampUsuari, string>> & { id_opció?: string };
export type Usuari = Partial<Record<CampUsuari, string> & { quota: Quota, número_de_targeta: string }> & { id: string };

export type Estat =
  | 'alta incompleta'
  | 'al corrent de pagament'
  | 'pendent de pagament';

const ContextDUsuari = createContext<{
  // `undefined` vol dir que encara no sabem..., `null` vol dir que no estem autenticats
  usuari: Usuari | null | undefined,
  registra: FuncióPerEnviarFormulari<ParàmetresDAlta>,
  entra: FuncióPerEnviarFormulari<ParàmetresDEntrada>,
  surt: () => void,
  recuperaLaContrasenya: (correuElectrònic: string) => Promise<boolean>,
  canviaLaContrasenya: (contrasenya: string, testimoni: string) => Promise<boolean>,
  canviaLaQuota: (id_quota: string) => Promise<boolean>,
  refresca: () => Promise<void>,
}>({
  usuari: undefined,
  registra: async () => ({}),
  entra: async () => ({}),
  surt: () => undefined,
  recuperaLaContrasenya: async (email) => false,
  canviaLaContrasenya: async (contrasenya, testimoni) => false,
  canviaLaQuota: async (id_quota) => false,
  refresca: async () => undefined,
});

export const usaUsuari = () => useContext(ContextDUsuari);

export const PàginaAmbUsuari: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [usuari, _posaUsuari] = useState<Usuari | null | undefined>(undefined);

  function posaUsuari(usuari: Usuari | null | undefined) {
    _posaUsuari(usuari);

    if (usuari) Sentry.setUser({ id: usuari.id });
    else Sentry.setUser(null);  
  }

  const registra = async (paràmetres: ParàmetresDAlta): Promise<ErrorsAlFormulari<ParàmetresDAlta>> => {
    const usuari = await put<Usuari>('usuari', paràmetres);
    if (usuari) {
      posaUsuari(usuari);
      return {};
    } else {
      return { 'correu_electrònic': 'Aquest correu electrònic ja està registrat' }
    }
  }

  const entra = async (paràmetres: ParàmetresDEntrada): Promise<ErrorsAlFormulari<ParàmetresDEntrada>> => {
    const usuari = await post<Usuari>('usuari', { operació: 'entra', ...paràmetres });
    posaUsuari(usuari);

    return { '': "Correu electrònic o contrasenya incorrectes. Sisplau revisa'ls." };
  }

  const surt = () => {
    document.cookie = 'testimoni_de_sessio=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    posaUsuari(null);
  }

  const recuperaLaContrasenya = async (correuElectrònic: string) => {
    return await post<boolean>('usuari', { operació: 'recupera-la-contrasenya', 'correu_electrònic': correuElectrònic }) ?? false;
  }

  const canviaLaContrasenya = async (contrasenya: string, testimoni: string) => {
    const usuari = await post<Usuari>('usuari', { operació: 'canvia-la-contrasenya', contrasenya, testimoni });
    if (usuari) posaUsuari(usuari);
    return !!usuari;
  }

  const canviaLaQuota = async (id_opció: string) => {
    const usuari = await post<Usuari>('quotes', { id_opció });
    if (usuari) posaUsuari(usuari);
    return usuari?.quota?.opció.id === id_opció;
  }

  const refresca = async () => {
    posaUsuari(undefined);
    const hi_ha_cookie = /(^|; )testimoni_de_sessio=/.test(document.cookie);
    const usuari = hi_ha_cookie ? await obtéUsuari() : null;
    posaUsuari(usuari);
  };

  useEffect(() => void refresca(), []);

  return (
    <ContextDUsuari.Provider value={{ usuari, entra, surt, registra, recuperaLaContrasenya, canviaLaContrasenya, canviaLaQuota, refresca }}>
      {children}
    </ContextDUsuari.Provider>
  );
}

export const PàginaProtegida: React.FC<{ children: React.ReactNode, camí: string }> = ({ children, camí }) => {
  const { usuari } = usaUsuari();
  const config = useStaticQuery(graphql`
    query { site { siteMetadata { caminsProtegits } } }
  `);
  const caminsProtegits = config?.site?.siteMetadata?.caminsProtegits ?? [];

  const protegir = camí.startsWith(caminsProtegits);

  useEffect(() => {
    if (protegir && usuari === null) {
      console.log('Pàgina protegida... anant a la pàgina de login.')
      void navigate('/entrar');
    }
  }, [usuari, protegir]);

  if (protegir && usuari === undefined) return <Carregant/>

  return <>{children}</>;
}

async function obtéUsuari(): Promise<Usuari | null> {
  const usuari = await get<Usuari>('usuari');
  return usuari;
}
