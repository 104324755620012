import React, { createContext } from 'react';
import Container from 'react-bootstrap/Container';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { BarraDeNavegació } from './BarraDeNavegació';
import { Row } from 'react-bootstrap';

const EstatDUsuari = createContext(null);

const CopyLeft = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" style={{verticalAlign: 'text-bottom'}}> <g> <path fill="none" d="M0 0H24V24H0z"/> <path d="M12 22C6.48 22 2 17.52 2 12S6.48 2 12 2s10 4.48 10 10-4.48 10-10 10zm0-5c2.76 0 5-2.24 5-5s-2.24-5-5-5c-1.82 0-3.413.973-4.288 2.428l1.715 1.028C9.952 9.583 10.907 9 12 9c1.658 0 3 1.342 3 3s-1.342 3-3 3c-1.093 0-2.05-.584-2.574-1.457l-1.714 1.03C8.587 16.026 10.18 17 12 17z"/> </g> </svg>
);

export const Pàgina: React.FC<{ children: React.ReactNode, títol: string }> = ({ children, títol }) => {
  const config = useStaticQuery(graphql`
    query { site { siteMetadata { title } } }
  `);
  const títolGlobal = config?.site?.siteMetadata?.title ?? '';

  return <>
    <Helmet>
      <title>{(títol ? títol + ' | ' : '') + títolGlobal}</title>
    </Helmet>
    <div className="bg-light">
      <BarraDeNavegació/>
      {children}
      <hr className="mt-5"/>
    </div>
    <Container className="d-flex flex-column flex-sm-row justify-content-center align-items-center">
      <p className="mx-3 text-center"><CopyLeft/> Casal Popular Lina Òdena</p>
      <p className="mx-3 text-center"><Link to="/privacitat">Condicions de privacitat</Link></p>
      <p className="mx-3 text-center"><a href="https://www.instagram.com/cp_linaodena/" className="link-primary"><i className="bi bi-instagram"></i> Instagram</a></p>
      <p className="mx-3 text-center"><a href="https://twitter.com/cp_linaodena" className="link-primary"><i className="bi bi-twitter"></i> Twitter</a></p>
    </Container>
  </>;
};
