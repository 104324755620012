import React, { createContext, FunctionComponent, ReactNode, useContext, useState } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

type Variant = 'danger' | 'warning' | 'success';

export type Avís = {
  títol?: string,
  missatge?: { __html: string } | ReactNode,
  variant?: Variant,
  aLes?: number,
};

const ICONA: Record<Variant, string> = {
  'danger': '⛔',
  'warning': '⚠',
  'success': '👍',
};

export let mostraUnAvís = (avís: Avís) => {};

function ésHtml(missatge: { __html: string } | ReactNode): missatge is { __html: string } {
  return !!missatge && typeof missatge === 'object' && '__html' in missatge;
}

const Avís: FunctionComponent<{ avís: Avís, enTancar: () => void }> = ({ avís, enTancar }) => {
  const { títol, missatge, variant } = avís;

  return (
    <Toast onClose={enTancar} bg={variant} delay={variant == 'danger' ? undefined : 3000} autohide={variant !== 'danger'}>
      {títol && <Toast.Header>
        <strong className="me-auto">{variant && ICONA[variant]} {títol}</strong>
      </Toast.Header>}
      {missatge && <Toast.Body>{ésHtml(missatge) ? <span dangerouslySetInnerHTML={missatge}/> : missatge}</Toast.Body>}
    </Toast>
  );
};

const Avisos: FunctionComponent<{ avisos: Avís[], enTancar: (i: number) => void }> = ({ avisos, enTancar }) => {
  const tancar = (i: number) => () => enTancar(i);

  return (
    <ToastContainer className="position-fixed" style={{ paddingTop: 100, zIndex: 1 }} position="top-center">
      {avisos.map((avís, i) => <Avís key={avís.aLes} avís={avís} enTancar={tancar(i)}/>)}
    </ToastContainer>
  );
};

export const PàginaAmbAvisos: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [avisos, posaAvisos] = useState<Avís[]>([]);

  mostraUnAvís = (avís) => {
    avís.aLes = new Date().getTime()
    posaAvisos([...avisos, avís].slice(-3));
  }

  const tancar = (i: number) => {
    avisos.splice(i, 1);
    posaAvisos([...avisos]);
  }

  return <>
    <Avisos avisos={avisos} enTancar={tancar}/>
    {children}
  </>;
}