import React, { createContext } from 'react';
import Container from 'react-bootstrap/Container';
import { Row } from 'react-bootstrap';
import { Pàgina } from './Pàgina';

export const PàginaSimple: React.FC<{ children: React.ReactNode, títol: string }> = ({ children, títol }) => (
  <Pàgina títol={títol}>
    <Container fluid="sm" className="mt-4 p-4" style={{ maxWidth: '540px' }}>
      <main style={{ fontFamily: 'Montserrat,Helvetica,Arial,Lucida,sans-serif', fontWeight: 300 }}>
        {children}
      </main>
    </Container>
  </Pàgina>
);

