import React from 'react';
import { PàginaSimple } from './src/components/PàginaSimple';
import { PàginaAmbUsuari, PàginaProtegida } from './src/components/Usuari';
import { PàginaAmbAvisos } from './src/components/Avisos';
import { MDXProvider } from '@mdx-js/react'
import MarkdownLink from './src/components/MarkdownLink'
import { Helmet } from 'react-helmet';

export const wrapPageElement = ({ element, props }) => (
  <PàginaProtegida camí={props.location.pathname}>
    {element}
  </PàginaProtegida>
);

export const wrapRootElement = ({ element }) => <>
  <Helmet>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossOrigin="anonymous"/>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css"/>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&amp;family=PT+Mono"/>
  </Helmet>
  <MDXProvider components={mdxRenderers}>
    <PàginaAmbAvisos>
      <PàginaAmbUsuari>
        {element}
      </PàginaAmbUsuari>
    </PàginaAmbAvisos>
  </MDXProvider>
</>;

const mdxRenderers = {
  a: (props) => <MarkdownLink {...props} />,
  wrapper: ({ children, pageContext }) => {
    const títol = pageContext?.frontmatter?.title;
  
    return <PàginaSimple títol={títol}>{children}</PàginaSimple>;
  },
}
