import * as React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import NavDropdown from 'react-bootstrap/Navdropdown';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { usaUsuari, Usuari } from './Usuari';
import { Carregant } from './Carregant';

const AmbUsuari: React.FunctionComponent<{ usuari: Usuari }> = ({ usuari }) => {
  const { surt } = usaUsuari();

  return (
    <NavDropdown title={usuari.nom}>
      <NavDropdown.Item as={Link} to="/socis">Quotes</NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/socis/rebuts">Rebuts</NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/canviar-contrasenya">Canvi de contrasenya</NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/" onClick={surt}><i className="bi-box-arrow-right"></i> Sortir</NavDropdown.Item>
    </NavDropdown>
  )
};

const SenseUsuari: React.FunctionComponent = () => <>
  <Button as={Link as any} className="btn-secondary mx-2" to="/entrar">Àrea personal</Button>
  <Button as={Link as any} to="/associar-se">Associa't</Button>
</>;

export const BarraDeNavegació: React.FunctionComponent<{}> = () => {
  const { usuari } = usaUsuari();

  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <div className="container-fluid">
        <Navbar.Brand as={Link} to="/">
          <StaticImage src="../imatges/Logo Lina.png" alt="Casal Popular Lina Òdena" style={{ width: 40, height: 40 }}/>
          <span className="align-middle"> &nbsp; Casal Popular Lina Òdena</span>
        </Navbar.Brand>
        <Stack direction="horizontal">
          {usuari === undefined 
            ? <Carregant/>
            : usuari
              ? <AmbUsuari usuari={usuari}/>
              : <SenseUsuari/>}
        </Stack>
      </div>
    </Navbar>
  );
}
