import dedent from 'dedent-js';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { Avís, mostraUnAvís } from "./components/Avisos";

const URL_ARREL = '/api/';

export async function get<T>(camí: string, params: Record<string, string> = {}): Promise<T | null> {
  return petició('GET', camí, params, undefined);
}

export async function post<T>(camí: string, càrrega: unknown): Promise<T | null> {
  return petició('POST', camí, undefined, càrrega);
}

export async function put<T>(camí: string, càrrega: unknown): Promise<T | null> {
  return petició('PUT', camí, undefined, càrrega);
}

type Error = {
  codi: number,
  hora: string,
  missatge: string,
};

async function petició<T>(
  mètode: string,
  camí: string,
  paràmetres: Record<string, string> | undefined = undefined,
  càrrega: unknown = undefined
): Promise<T | null> {
  const url = URL_ARREL + camí + '.php' + (paràmetres ? '?' + new URLSearchParams(paràmetres) : '');
  const resposta = await fetch(url, {
    method: mètode,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(càrrega)
  });
  if (resposta.ok) return await resposta.json() as T;
  else {
    let error: Error | undefined;
    try {
      error = await resposta.json() as Error;
      console.log({ error });
    } catch {}

    if (error?.codi === 401) {
      mostraUnAvís(error.missatge as Avís);
    } else {
      mostraUnAvís({
        títol: 'Error intern',
        missatge: <span>
          S'ha produit un error. Sisplau <a href="javascript:location.reload()">
          recarrega la pàgina</a> per tornar-ho a intentar o escriu
          a <a href="mailto:cplinaodena+tic@gmail.com">cplinaodena+tic@gmail.com</a>
        </span>,
        variant: 'danger'
      });
      Sentry.captureMessage('Error al PHP: ' + error?.missatge);
    }
    return null;
  }
}
